import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';
import NotFoundIcon from 'components/icons/NotFoundIcon';
import { Button } from 'components/common/button/button';

import { MainContentArea, MaintenanceWrapper } from 'assets/styles/pages.style';

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <MainContentArea>
      <MaintenanceWrapper>
        <NotFoundIcon />
        <h2>{t('page404.title')}</h2>
        <p>{t('page404.text')}</p>
        <Button onClick={() => navigate('/')} fullwidth>
          {t('page404.link')}
        </Button>
      </MaintenanceWrapper>
    </MainContentArea>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.404.title')} />;
};
