const NotFoundIcon = () => {
  return (
    <svg
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.291 0H14.4141C6.45334 0 0 6.45334 0 14.4141V43.2422C0 45.8957 2.15122 48.0469 4.80469 48.0469H80.0781L93.291 0Z"
        fill="#BDCAF8"
      />
      <path
        d="M149.586 0H93.291L76.5054 40.2854C75.7593 42.0759 74.0101 43.2422 72.0703 43.2422H0V149.586C0 157.547 6.45334 164 14.4141 164H69.1875H94.8125H149.586C157.547 164 164 157.547 164 149.586V14.4141C164 6.45334 157.547 0 149.586 0Z"
        fill="#E0E7FF"
      />
      <path
        d="M24.0234 26.4258C26.677 26.4258 28.8281 24.2746 28.8281 21.6211C28.8281 18.9675 26.677 16.8164 24.0234 16.8164C21.3699 16.8164 19.2188 18.9675 19.2188 21.6211C19.2188 24.2746 21.3699 26.4258 24.0234 26.4258Z"
        fill="white"
      />
      <path
        d="M43.2422 26.4258C45.8957 26.4258 48.0469 24.2746 48.0469 21.6211C48.0469 18.9675 45.8957 16.8164 43.2422 16.8164C40.5886 16.8164 38.4375 18.9675 38.4375 21.6211C38.4375 24.2746 40.5886 26.4258 43.2422 26.4258Z"
        fill="white"
      />
      <path
        d="M62.4609 26.4258C65.1145 26.4258 67.2656 24.2746 67.2656 21.6211C67.2656 18.9675 65.1145 16.8164 62.4609 16.8164C59.8074 16.8164 57.6562 18.9675 57.6562 21.6211C57.6562 24.2746 59.8074 26.4258 62.4609 26.4258Z"
        fill="white"
      />
      <path
        d="M31.5048 99.5238V99.5873C31.5048 99.6296 31.5259 99.6508 31.5682 99.6508H42.0234C42.4036 99.6508 42.5937 99.4815 42.5937 99.1429V83.7778C42.5937 83.7354 42.5726 83.7143 42.5304 83.7143C42.4459 83.7143 42.4036 83.7354 42.4036 83.7778L31.5048 99.5238ZM25.5485 108.095C24.5769 108.095 23.732 107.735 23.0138 107.016C22.3379 106.296 22 105.45 22 104.476V103.27C22 100.899 22.697 98.7196 24.0911 96.7302L40.5027 73.5556C41.8967 71.6085 43.7765 70.6349 46.1422 70.6349H49.3104C50.282 70.6349 51.1058 70.9947 51.7817 71.7143C52.4998 72.4339 52.8589 73.2804 52.8589 74.254V99.1429C52.8589 99.4815 53.049 99.6508 53.4292 99.6508H55.9638C56.9354 99.6508 57.7803 100.011 58.4984 100.73C59.2165 101.45 59.5756 102.296 59.5756 103.27V104.476C59.5756 105.45 59.2165 106.296 58.4984 107.016C57.7803 107.735 56.9354 108.095 55.9638 108.095H53.4292C53.049 108.095 52.8589 108.286 52.8589 108.667V113.365C52.8589 114.339 52.4998 115.185 51.7817 115.905C51.1058 116.624 50.282 116.984 49.3104 116.984H46.1422C45.1706 116.984 44.3257 116.624 43.6076 115.905C42.9317 115.185 42.5937 114.339 42.5937 113.365V108.667C42.5937 108.286 42.4036 108.095 42.0234 108.095H25.5485Z"
        fill="white"
      />
      <path
        d="M88.6217 81.6825C87.4389 79.3545 85.5801 78.1905 83.0455 78.1905C80.5109 78.1905 78.6311 79.3545 77.406 81.6825C76.2232 84.0106 75.6318 88.0529 75.6318 93.8095C75.6318 99.5661 76.2232 103.608 77.406 105.937C78.6311 108.265 80.5109 109.429 83.0455 109.429C85.5801 109.429 87.4389 108.265 88.6217 105.937C89.8467 103.608 90.4593 99.5661 90.4593 93.8095C90.4593 88.0529 89.8467 84.0106 88.6217 81.6825ZM96.8592 112.032C93.8599 115.757 89.2553 117.619 83.0455 117.619C76.8357 117.619 72.2101 115.757 69.1685 112.032C66.1692 108.307 64.6696 102.233 64.6696 93.8095C64.6696 85.3862 66.1692 79.3122 69.1685 75.5873C72.2101 71.8624 76.8357 70 83.0455 70C89.2553 70 93.8599 71.8624 96.8592 75.5873C99.9007 79.3122 101.421 85.3862 101.421 93.8095C101.421 102.233 99.9007 108.307 96.8592 112.032Z"
        fill="white"
      />
      <path
        d="M113.929 99.5238V99.5873C113.929 99.6296 113.95 99.6508 113.993 99.6508H124.448C124.828 99.6508 125.018 99.4815 125.018 99.1429V83.7778C125.018 83.7354 124.997 83.7143 124.955 83.7143C124.87 83.7143 124.828 83.7354 124.828 83.7778L113.929 99.5238ZM107.973 108.095C107.001 108.095 106.156 107.735 105.438 107.016C104.762 106.296 104.424 105.45 104.424 104.476V103.27C104.424 100.899 105.121 98.7196 106.515 96.7302L122.927 73.5556C124.321 71.6085 126.201 70.6349 128.567 70.6349H131.735C132.706 70.6349 133.53 70.9947 134.206 71.7143C134.924 72.4339 135.283 73.2804 135.283 74.254V99.1429C135.283 99.4815 135.473 99.6508 135.854 99.6508H138.388C139.36 99.6508 140.205 100.011 140.923 100.73C141.641 101.45 142 102.296 142 103.27V104.476C142 105.45 141.641 106.296 140.923 107.016C140.205 107.735 139.36 108.095 138.388 108.095H135.854C135.473 108.095 135.283 108.286 135.283 108.667V113.365C135.283 114.339 134.924 115.185 134.206 115.905C133.53 116.624 132.706 116.984 131.735 116.984H128.567C127.595 116.984 126.75 116.624 126.032 115.905C125.356 115.185 125.018 114.339 125.018 113.365V108.667C125.018 108.286 124.828 108.095 124.448 108.095H107.973Z"
        fill="white"
      />
    </svg>
  );
};

export default NotFoundIcon;
